import React from "react"
import image1 from "../images/IYA.png"
import image2 from "../images/oiltree.png"
import image3 from "../images/remotejoblife.png"

const Portfolio = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <p className="text-muted para-desc mx-auto mb-0">
                These are the results of some of our latest missions.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 mt-4 pt-2">
            <div className="work-container position-relative rounded">
              <img
                src={image1}
                className="img-fluid rounded"
                alt="in your area screenshot"
              />
              <div className="overlay-work"></div>
              <div className="content">
                <p
                  className="title text-white d-block font-weight-bold"
                >
                  InYourArea.co.uk
                </p>
              </div>
              <div className="client">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Reach PLC
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 2018
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 mt-4 pt-2">
            <div className="work-container position-relative shadow rounded">
              <img
                src="https://cnet3.cbsistatic.com/img/nWUXEcoodFoAaZ4lADvjgj-V2pI=/1200x675/2019/11/22/26777f92-446a-4b1d-ad86-cdcf94fe0dce/echo-show-8-3.jpg"
                className="img-fluid rounded"
                alt="amazon alexa device"
              />
              <div className="overlay-work"></div>
              <div className="content">
                <p
                  className="title text-white d-block font-weight-bold"
                >
                  Amazon Alexa Feature
                </p>
              </div>
              <div className="client">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Amazon
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 2019
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 mt-4 pt-2">
            <div className="work-container position-relative shadow rounded">
              <img
                src={image3}
                className="img-fluid rounded"
                alt="remote job life website screenshot"
              />
              <div className="overlay-work"></div>
              <div className="content">
                <p
                  className="title text-white d-block font-weight-bold"
                >
                  Remote Job Life
                </p>
              </div>
              <div className="client">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> remotejob.life
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 2019
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 mt-4 pt-2">
            <div className="work-container position-relative shadow rounded">
              <img
                src={image2}
                className="img-fluid rounded"
                alt="oiltree project ecommerce store"
              />
              <div className="overlay-work"></div>
              <div className="content">
                <p
                  className="title text-white d-block font-weight-bold"
                >
                  Oiltree Project
                </p>
              </div>
              <div className="client">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Oiltree
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 2017
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title">
              <h4 className="title mb-4">
                Impressive work right!
              </h4>
              <p className="text-muted para-desc mx-auto mb-0">
                We can't wait to impress you too.
              </p>

              <div className="mt-3">
                <a href="/contactus" className="btn btn-secondary mt-2 mr-2">
                  Launch your mission
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
